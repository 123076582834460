import SliderPart from "../../components/HomePageComponents/HomePageSlider/HomePageSlider";
import HomePageAccordian from "../../components/HomePageComponents/HomePageAccordian/HomePageAccordian";
import AboutCompany from "../../components/HomePageComponents/aboutCompany/AboutCompany";
import MidPart from "../../components/HomePageComponents/midPart/MidPart.component";
import Video from "../../components/HomePageComponents/Video/Video.component";
import Photos from "../../components/HomePageComponents/PhotosComponent/Photos.component";
import { Helmet } from "react-helmet";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>
          تولید کننده خاک پوششی بستر رویش قارچ و کمپوست | کیمیا گستر
        </title>
        <meta
          name="description"
          content=" کیمیا گستر تولید کننده انواع خاک پوششی بستر رویش قارچ از معادن خاک استان‌های شمال و شمال غرب کشور. ثبت سفارش از طریق شماره تماس 09183141367 و اینستاگرام."
        />
      </Helmet>
      <AboutCompany />
      <MidPart />
      <Video />
      <Photos />
      <SliderPart />
      <div style={{ marginInline: "auto" }}>
        <HomePageAccordian />
      </div>
    </>
  );
}

export default HomePage;
