import Accordian from "./AccordianComponent/Accordian.component";
import "./HomePageAccordian.css";

const accordian = [
  {
    header: "آیا سفارش محصول به صورت غیر حضوری امکان پذیر است؟",
    maintext:
      "بله، امکان ثبت سفارش از طریق شماره تماس 09183141367 ، اینستاگرام و تلگرام وجود دارد.",

    index: 1,
  },
  {
    header: "امکان مشاوره پیش و پس از خرید در شرکت کیمیا گستر وجود دارد؟",
    maintext:
      "بله، مشاوره پیش و پس از خرید در مجموعه کیمیا گستر از طریق مدیریت و نمایندگان مجموعه انجام می‌شود.",
    index: 2,
  },
  {
    header:
      "آیا محصولات شرکت کیمیا گستر با نام، پسوند، پیشوند و یا برند دیگری عرضه می‌شود؟",
    maintext:
      "خیر، تمامی محصولات کیمیا گستر با نام اختصاصی 'کیمیا گستر' و لوگو درج شده درسایت به فروش می‌رسد.",
    index: 3,
  },
  {
    header: "آیا بافت مورد سفارش از شرکت کیمیا گستر دارای تداوم است؟",
    maintext:
      "بله، خرید مواد خام در شرکت کیمیا گستر به صورت کلی است و مصرف مجموعه را حداقل برای 2 الی 3 سال پوشش می‌دهد.",
    index: 4,
  },
  {
    header:
      " آیا وزن کیسه‌های خاک تولید شده در کیمیا گستر دارای تلرانس خاصی است؟",
    maintext:
      "بله، تمامی کیسه‌های خاک تولید شده در شرکت کیمیا گستر دارای وزن 20 Kg و با تلرانس 200 g می‌باشد.",
    index: 5,
  },
];

function HomePageAccordian() {
  return (
    <div className="accordians-component">
      <h2>سوالات متداول مشتریان کیمیا گستر</h2>
      <p style={{ direction: "rtl", textAlign: "center" }}>
        اگر به سوال شما در قسمت زیر پاسخ داده نشده، از طریق راه‌های ارتباطی سوال
        خود را مطرح بفرمایید.
      </p>

      <div className="accordians-component-main">
        <div className="accordians">
          {accordian.map((v, i) => {
            return <Accordian key={i} accordianValue={v} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default HomePageAccordian;
