import "./Video.css";
import video from "../../../assets/video/4_5929278819725742637.mp4";
function Video() {
  return (
    <div className="video">
      <h2>معادن اسفگنوم شمال غرب کشور</h2>
      <p style={{ direction: "rtl" }}>
        استخراج اسفگنوم از عمق 10 - 14 متری با بافتی فوق العاده و عاری از هر
        گونه بیماری به دلیل عمق زیاد معدن.
      </p>

      <div className="div_video">
        <video src={video} controls />
      </div>
    </div>
  );
}

export default Video;
