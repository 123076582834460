import styles from "./Navbar.module.css";
import logo from "../../assets/images/photo_2023-08-19_00-48-27-removebg-preview 1.svg";
import phoneimg from "../../assets/images/phone-call-svgrepo-com 1.svg";
import instagramImg from "../../assets/images/instagram-apps-icon-free-png.svg";
import menu from "../../assets/images/menu.svg";
import env from "react-dotenv";

import { useRef, useState } from "react";
function Navbar() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [opendropdown, setOpenDropDown] = useState(false);
  return (
    <div className={styles.c}>
      <nav className={styles.navbar}>
        {windowSize.current[0] > 1000 ? (
          <>
            <div
              style={{
                height: "100px",
                paddingTop: "10px",
                marginRight: "12px",
              }}
            >
              <img src={logo} style={{ height: "80px" }} />
            </div>
            <div className={styles.navItems}>
              <div className={styles.navItem}>
                <a href="/">صفحه اصلی</a>
              </div>

              <div className={styles.k}>
                <a>کمپوست</a>
                <div className={styles.soon}>به زودی</div>
              </div>

              <div className={styles.navItem}>
                <a href="/soil">خاک</a>
                <div className={styles.drop_down}>
                  <a href="/soil/peat-soil">خاک پیت شمال کشور</a>
                  <a href="/soil/sphagnum-soil">خاک اسفاگنوم</a>
                </div>
              </div>

              <div className={styles.navItem}>
                <a href={`https://kimiagostarsoil.com/blog`}>وبلاگ</a>
              </div>

              <div className={styles.navItem}>
                <a href="/about-us">درباره ما</a>
              </div>

              <div className={styles.navItem}>
                <a href="/contact-us">تماس با ما</a>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "30px",
                position: "absolute",
                top: 0,
              }}
            >
              <div
                style={{
                  width: "110px",
                  direction: "ltr",
                  textAlign: "left",
                  paddingLeft: "0px",
                }}
              >
                <p style={{ fontSize: "12px", cursor: "pointer" }}>
                  {" "}
                  <a href="tel:09183141367" style={{ all: "unset" }}>
                    با ما تماس بگیرید
                  </a>
                </p>
                <p style={{ fontSize: "16px", cursor: "pointer" }}>
                  {" "}
                  <a href="tel:0918314 1367" style={{ all: "unset" }}>
                    0918 314 1367
                  </a>{" "}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                <a href="tel:09183141367" style={{ all: "unset" }}>
                  <img src={phoneimg} style={{ width: "25px" }} />
                </a>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.responsiveNavbar}>
            <div
              // className={
              //     opendropdown
              //         ? (styles.drop_down_menu, styles.show)
              //         : styles.drop_down_menu
              // }

              className={
                opendropdown
                  ? `${styles.drop_down_menu}
                                  ${styles.dropdownshow}`
                  : `${styles.drop_down_menu}`
              }
            >
              <div className={styles.navItems}>
                <div className={styles.navItem}>
                  <a href="/">صفحه اصلی</a>
                </div>

                <div className={styles.navItem}>
                  <a>کمپوست</a>
                  <div className={styles.soon}>به زودی</div>
                </div>

                <div className={styles.navItem}>
                  <a href="/soil">خاک</a>
                  <div className={styles.drop_down}>
                    <a href="/soil/peat-soil">خاک پیت شمال کشور</a>
                    <a href="/soil/sphagnum-soil">خاک اسفاگنوم</a>
                  </div>
                </div>

                <div className={styles.navItem}>
                  <a href={`https://kimiagostarsoil.com/blog`}>وبلاگ</a>
                </div>

                <div className={styles.navItem}>
                  <a href="/about-us">درباره ما</a>
                </div>

                <div className={styles.navItem}>
                  <a href="/contact-us">تماس با ما</a>
                </div>
              </div>
            </div>
            <div className={styles.rightNavbar}>
              <div
                style={{
                  width: "30%",
                  height: "100%",
                  overflow: "visible",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img
                  className={styles.menuIcon}
                  src={menu}
                  style={{ width: "100%" }}
                  alt=""
                  onClick={() => {
                    setOpenDropDown(!opendropdown);
                  }}
                />
              </div>
              <div
                style={{
                  width: "70%",
                  height: "100%",
                  overflow: "visible",
                }}
              >
                <img
                  src={logo}
                  style={{ height: "100%", width: "100%" }}
                  alt=""
                />
              </div>
            </div>
            <div className={styles.leftNavbar}>
              <div className={styles.leftNavbarPhone}>
                <div className={styles.lefTextcontainer}>
                  <p style={{ fontSize: "10px", cursor: "pointer" }}>
                    <a href="tel:09183141367" style={{ all: "unset" }}>
                      0918 314 1367
                    </a>
                  </p>
                  <p style={{ fontSize: "7px", cursor: "pointer" }}>
                    {" "}
                    <a href="tel:09183141367" style={{ all: "unset" }}>
                      با ما تماس بگیرید
                    </a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <a href="tel:09183141367" style={{ all: "unset" }}>
                    <img src={phoneimg} className={styles.phoneIcon} />
                  </a>
                </div>
              </div>
              <div className={styles.instagramIcon}>
                <span className={styles.line}></span>
                <a href="">
                  <img src={instagramImg} alt="" />
                </a>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
