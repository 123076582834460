import "./ContactUsPage.css";
import image from "../../assets/images/header.svg";
import imagebig from "../../assets/images/kkkkkkkk.svg";
import { Helmet } from "react-helmet";
function ContactUsPage() {
  const screenwidth = window.innerWidth;
  return (
    <>
      <Helmet>
        <title>تماس با ما، ثبت سفارش به صورت حضوری و تلفنی | کیمیا گستر</title>
        <meta
          name="description"
          content=' تمامی محصولات کیمیا گستر با نام اختصاصی "کیمیا گستر" و لوگو درج شده در سایت به فروش می‌رسد. در صورت نیاز به مشاوره از طریق راه‌های ارتباطی با ما تماس بگیرید.
'
        />
      </Helmet>
      <div className="header-photo">
        {screenwidth < 1800 ? (
          <img src={image} alt="poster" />
        ) : (
          <img src={imagebig} alt="poster" />
        )}
        <span>تماس با ما</span>
      </div>
      <div className="contact-us-page">
        <div className="contact-us-main">
          <div className="contact-us-main-text">
            <h2>تماس با مجموعه کیمیا گستر</h2>
            <p>شما می‌توانید از طریق راه‌های ارتباطی سفارش خود را ثبت کنید.</p>
          </div>

          <div className="contact-us-items">
            <h3>آدرس:</h3>
            <div className="contact-us-items-container">
              <p className="contact-us-item">
                شعبه 1: همدان - کیلومتر 10 جاده ملایر
              </p>
              <p className="contact-us-item">
                شعبه 2: همدان - کیلومتر 10 جاده تهران (به زودی)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsPage;
