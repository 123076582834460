import "./midPart.css";
import MidComponent from "./midComponent/Mid.component";
import image1 from "./../../../assets/images/sticker/drop.png";
import image2 from "./../../../assets/images/sticker/no-virus.png";
import image3 from "./../../../assets/images/sticker/chemist.png";
const midVar = [
  {
    index: 1,
    src: image1,
    title: "جذب آب بالا",
    text: "خاک‌های تولیدی شرکت کیمیا گستر آب مصرفی را به خوبی در خود جذب و ذخیره می‌کنند.",
  },
  {
    index: 2,
    src: image2,
    title: "شیمی مناسب",
    text: "با توجه به ترکیبات مغذی غنی، pH و Ec مناسب و ساختار خوب، خاک‌های کیمیا گستر از شیمی مناسبی برخوردارند.",
  },
  {
    index: 3,
    src: image3,
    title: "فاقد آلودگی",
    text: "عدم وجود ویروس و آلودگی‌های پنهان از دیگر ویژگی‌های انواع خاک‌های کیمیا گستر است.",
  },
];

function MidPart() {
  return (
    <div
      className="mid-components"
      style={{ direction: "rtl", textAlign: "justify" }}
    >
      {midVar.map((v) => {
        return <MidComponent key={v.index} midValue={v} />;
      })}
    </div>
  );
}

export default MidPart;
