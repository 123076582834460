import companyImg from "../../../assets/images/ncnn.svg";
import phoneIcon from "../../../assets/images/phone-call-svgrepo-com.svg";
import posterPhoto from "../../../assets/images/home-1 1.svg";
import posterPhotobig from "../../../assets/images/llllllll.svg";

import "./AboutCompany.css";
import { useState } from "react";
function AboutCompany() {
  const [tesxt, setText] = useState(
    "  با توجه به تاثیر گذاری خاک پوششی در راندمان، کیفیت، ماندگاری و درخشندگی قارچ، انتخاب بستر رویش مناسب امری مهم به شمار می‌رود. کیمیا گستر با استفاده از معادن خاک پیت استان‌های شمال غرب و شمال کشور، محصولی با کیفیت در اختیار مشتریان می‌گذارد با توجه به تاثیر گذاری خاک پوششی در راندمان، کیفیت، ماندگاری و درخشندگی قارچ، انتخاب بستر رویش مناسب امری مهم به شمار می‌رود. کیمیا گستر با استفاده از معادن خاک پیت استان‌های شمال غرب و شمال کشور، محصولی با کیفیت در اختیار  مشتریان می‌گذارد"
  );
  const screenwidth = window.innerWidth;
  console.log(screenwidth, "this is screen wirdth");
  return (
    <>
      <div className="poster">
        {screenwidth < 1800 ? (
          <img src={posterPhoto} alt="poster" />
        ) : (
          <img src={posterPhotobig} alt="poster" />
        )}

        <div className="poster-rectangle"></div>
        <div className="poster-text">
          <h1>
            کیمیا گستر<br></br> تولید کننده خاک پوششی بستر رویش قارچ
          </h1>
          <p style={{ direction: "rtl", textAlign: "justify" }}>
            با توجه به تاثیر گذاری خاک پوششی در راندمان، کیفیت، ماندگاری
            ودرخشندگی قارچ، انتخاب بستر رویش مناسب امری مهم به شمار می‌رود.
            کیمیاگستر با استفاده از معادن خاک پیت استان‌های شمال غرب و شمال
            کشور،محصولی با کیفیت در اختیار مشتریان می‌گذارد با توجه به تاثیر
            گذاریخاک پوششی در راندمان، کیفیت، ماندگاری و درخشندگی قارچ، انتخاب
            بستررویش مناسب امری مهم به شمار می‌رود. کیمیا گستر با استفاده از
            معادنخاک پیت استان‌های شمال غرب و شمال کشور، محصولی با کیفیت در
            اختیار مشتریان می‌گذارد.
          </p>
          <a href="/contact-us" className="btn" style={{ all: "unset" }}>
            <button style={{ cursor: "pointer" }} className="change btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                viewBox="0 0 26 24"
                fill="none"
                className="change"
              >
                <path
                  d="M0.939341 10.9393C0.353554 11.5251 0.353554 12.4749 0.939341 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939341 10.9393ZM26 10.5L2 10.5V13.5L26 13.5V10.5Z"
                  fill="#DBDBDB"
                />
              </svg>
              تماس بگیرید
            </button>
          </a>
        </div>
      </div>

      <div className="company-info">
        <div className="company-info-text">
          <h2>اینستاگرام کیمیا گستر</h2>
          <p style={{ direction: "rtl" }}>
            تمامی فعالیت‌ها و فروش محصولات مجموعه کیمیا گستر در اینستاگرام، از
            طریق این آدرس می‌باشد.
          </p>
        </div>

        <div className="btn2">
          <a href="https://www.instagram.com/kimiagostarsoil" target="_blank">
            <button>kimiagostarsoil</button>
          </a>
        </div>
      </div>

      <div className="about-company">
        <div className="company-image">
          <img src={companyImg} alt="company" />
          <span className="square"></span>
        </div>
        <div className="about-company-text">
          <div className="texts">
            <h2>پاستورازیسیون محصولات کیمیا گستر</h2>
            <p style={{ direction: "rtl", textAlign: "justify" }}>
              یکی از معضلات پاستورازیسیون خاک، پاک کردن آلودگی‌های خاکزی معمولا
              روش‌های قدیمی پاسخگو نیست. از این رو شرکت کیمیا گستر با بهره گیری
              از ماشین آلات پیشرفته، به چرخش چهار جهته خاک همراه با تزریق بخار
              در مدت زمان لازم اقدام نموده است. علاوه بر آن با تمرکز بر حفظ
              باکتری‌های مفید خاک از جمله سودوموناس پوتیدا (Pseudomonas putida)
              رضایت تمامی مشتریان را جلب کرده است.
            </p>
          </div>

          <div className="company-telephone-info">
            <a href="tel:09183141367" className="btn">
              <button className="call">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 26 24"
                  fill="#DBDBDB"
                  style={{ border: "none" }}
                >
                  <path
                    d="M0.939341 10.9393C0.353554 11.5251 0.353554 12.4749 0.939341 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939341 10.9393ZM26 10.5L2 10.5V13.5L26 13.5V10.5Z"
                    fill="#DBDBDB"
                  />
                </svg>
                تماس بگیرید
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutCompany;
