import styles from "./about.module.css";
import img from "../../assets/images/about/photo1707664909(1).jpeg";
import image from "../../assets/images/header.svg";
import imagebig from "../../assets/images/kkkkkkkk.svg";
import { Helmet } from "react-helmet";
import Accordian from "../../components/HomePageComponents/HomePageAccordian/AccordianComponent/Accordian.component";
function About() {
  const accordian = [
    {
      header: "آیا سفارش محصول به صورت غیر حضوری امکان پذیر است؟",
      maintext:
        "بله، امکان ثبت سفارش از طریق شماره تماس 09183141367 ، اینستاگرام و تلگرام وجود دارد.",

      index: 1,
    },
    {
      header: "امکان مشاوره پیش و پس از خرید در شرکت کیمیا گستر وجود دارد؟",
      maintext:
        "بله، مشاوره پیش و پس از خرید در مجموعه کیمیا گستر از طریق مدیریت و نمایندگان مجموعه انجام می‌شود.",
      index: 2,
    },
    {
      header:
        "آیا محصولات شرکت کیمیا گستر با نام، پسوند، پیشوند و یا برند دیگری عرضه می‌شود؟",
      maintext:
        "خیر، تمامی محصولات کیمیا گستر با نام اختصاصی 'کیمیا گستر' و لوگو درج شده درسایت به فروش می‌رسد.",
      index: 3,
    },
    {
      header: "آیا بافت مورد سفارش از شرکت کیمیا گستر دارای تداوم است؟",
      maintext:
        "بله، خرید مواد خام در شرکت کیمیا گستر به صورت کلی است و مصرف مجموعه را حداقل برای 2 الی 3 سال پوشش می‌دهد.",
      index: 4,
    },
    {
      header:
        "آیا وزن کیسه‌های خاک تولید شده در کیمیا گستر دارای تلرانس خاصی است؟",
      maintext:
        "بله، تمامی کیسه‌های خاک تولید شده در شرکت کیمیا گستر دارای وزن 20 Kg و با تلرانس 200 g می‌باشد.",
      index: 5,
    },
  ];
  const screenwidth = window.innerWidth;
  return (
    <>
      <Helmet>
        <title style={{ direction: "rtl" }}>
          درباره ما، ارائه دهنده خاک پوششی بستر رویش قارچ و کمپوست | کیمیا گستر
        </title>
        <meta
          name="description"
          content="با توسعه مجموعه کیمیا گستر و در عین حال حفظ و ارتقا کیفیت، تولید محصولات خود اعم از کمپوست و انواع خاک پوششی بستر رویش قارچ را گسترش و افزایش داده‌ایم."
        />
      </Helmet>
      {/* <div style={{height:"100px"}}></div> */}
      <div className={styles.headerPhoto}>
        {screenwidth < 1800 ? (
          <img src={image} alt="poster" />
        ) : (
          <img src={imagebig} alt="poster" />
        )}
        <span> درباره ما</span>
      </div>

      <div className={styles.container1}>
        <div className={styles.container2}>
          <div style={{ paddingLeft: "3%" }}>
            <h2 className={styles.h2tag3}>درباره مجموعه کیمیا گستر</h2>

            <div className={styles.t1}></div>
          </div>
          <div className="btn">
            <a href="tel:09183141367" style={{ all: "unset" }}>
              <button className={styles.cbutton}>با ما تماس بگیرید</button>
            </a>
          </div>
        </div>
      </div>

      <div className={styles.container3}>
        <img src={img} className={styles.img1} />
        <p className={styles.t2}>
          سفارشات خاک مجموعه کیمیا گستر فقط از طریق نمایندگان محترم استان‌ها و
          محل های فاقد نمایندگی بصورت مستقیم از این طریق انجام می‌گیرد و خارج از
          آن جعلی و نامعتبر می باشد. خاک پوششی که در شرکت کیمیا گستر تولید
          می‌شود حتما از این نوع خاک بهره می‌برد و یکی از عواملی که باعث تمایز
          خاک تولیدی در کیمیا گستر گردیده وجود همین ترکیب در فرآوری و تولید خاک
          شده که به لطف خداوند و همت و پشتکار دانش کارشناسان شرکت کیمیا گستر این
          امر محقق شده و می‌توان گفت که رضایت اکثریت مشتریان این شرکت افتخار
          بزرگی و مایه مباهات است که این امر نتیجه جدیت،.تلاش و استفاده از معادن
          استاندارد و تحقیقات و بهره گیری از ماشین آلات پیشرفته این شرکت می
          باشد.
        </p>
      </div>

      {/* <>------------------------------*****************-----------------</> */}

      <div style={{ marginTop: "100px" }}>
        <h2 className={styles.h2tag}>
          چرا محصول خود را از کیمیا گستر سفارش دهیم؟
        </h2>
        <div className={styles.container4}>
          <div className={styles.container5}>
            <div className={styles.l1}>سفارشی سازی بسته بندی</div>
            <div className={styles.lt1}>
              در شرکت کیمیا گستر با توجه به نیاز و درخواست مشتری، امکان تغییر
              بسته بندی و حجم محصول وجود دارد.
            </div>
          </div>

          <div className={styles.container5}>
            <div className={styles.l1}>خاک با بافت دارای تداوم</div>
            <div className={styles.lt1}>
              خرید مواد خام به صورت کلی است و مصرف مجموعه را حداقل برای 2 الی 3
              سال پوشش می‌دهد.
            </div>
          </div>

          <div className={styles.container5}>
            <div className={styles.l1}>تغییرات بافت خاک</div>
            <div className={styles.lt1}>
              در صورت نیاز مشتری امکان تغییر در بافت خاک‌های شرکت کیمیا گستر با
              توجه به شرایط اقلیمی هر استان وجود دارد.
            </div>
          </div>
        </div>
      </div>

      {/* <>------------------------------*****************-----------------</> */}
      {/* 
      <div style={{ width: "100%", marginTop: "100px" }}>
        <img src={img1} style={{ width: "100%" }} />
      </div> */}

      {/* <>------------------------------*****************-----------------</> */}

      <h2 className={styles.h2tag2}>مجموعه کیمیا گستر</h2>
      <p
        style={{
          fontSize: "16px",

          textAlign: "center",
          background: "#F7F7F7",
        }}
      >
        {" "}
      </p>

      <div className={styles.endc}>
        <div className={styles.cc}>
          <div className={styles.imagesSection}>
            <h1 className={styles.imagetxt}>محمدرضا امیری</h1>
          </div>
          <div className={styles.sectext}> </div>
        </div>

        <div className={styles.cc}>
          <div className={styles.imagesSection1}>
            <h1 className={styles.imagetxt}>پژمان امیری</h1>
          </div>
          <div className={styles.sectext}> </div>
        </div>
      </div>

      {/* <>------------------------------*****************-----------------</> */}

      <div
        style={{
          width: "70%",
          textAlign: "center",
          marginInline: "auto",
          marginTop: "50px",
        }}
      >
        {accordian.map((v, i) => {
          return <Accordian key={i} accordianValue={v} />;
        })}
      </div>
    </>
  );
}

export default About;
