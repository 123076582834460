import "./HomePageSlider.css";
import Slider from "./Slider/Slider.component";
import { useState } from "react";

const slider = [
    {
        index: 0,
        text: `محصول سفارشی ما در کوتاه ترین زمان ممکن و همراه با کیفیت مورد انتظار تحویل داده شد.`,
        info: "خراسان رضوی",
    },
    {
        index: 1,
        text: `ضمن قدردانی و سپاس از مجموعه کیمیا گستر بابت تغییرات بافت خاک نسبت به شرایط اقلیمی استان ما.`,
        info: "فارس - شیراز",
    },
    {
        index: 2,
        text: `شرکت کیمیا گستر از معدود تولید کنندگان بستر رویش قارچ در کشور است که با توجه به نیاز و درخواست مشتری، شرایط بسته بندی و حجم محصول را تغییر می‌دهد. سفارش ما به درخواست شرکت به صورت جانبو بگ ارسال گردید.`,
        info: "قزوین - آبیک",
    },
];

function SliderPart() {
    let [activeSlider, setActiveSlider] = useState(2);
    function handleSetActiveSlider(number) {
        setActiveSlider(number);
    }
    console.log(activeSlider);
    return (
        <div className="sliderPart">
            <div className="slider-text">
                <h2>نظرات مشتریان شرکت کیمیا گستر</h2>
            </div>

            <div className="slider">
                {slider.map((v) => (
                    <Slider
                        key={v.index}
                        sliderPart={v}
                        active={activeSlider === v.index ? true : false}
                        handleSetActiveSlider={handleSetActiveSlider}
                        maxSliderIndex={slider.length - 1}
                    />
                ))}

                <div className="indicators">
                    {slider.map((_, i) => {
                        return (
                            <div
                                className={`${
                                    i === activeSlider
                                        ? "indicator active"
                                        : "indicator"
                                }`}
                                onClick={() => {
                                    setActiveSlider(i);
                                }}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default SliderPart;
