import styles from "./footer.module.css";
import insticon from "../../assets/icons/icons8-instagram.svg";
import linkicon from "../../assets/icons/icons8-linkedin.svg";
import telicon from "../../assets/icons/icons8-telegram(1).svg";
import logo from "../../assets/images/photo_2023-08-19_00-48-27-removebg-preview 1.svg";
function Footer() {
  return (
    <>
      <div className={styles.container}>
        <div style={{ display: "flex" }}>
          <div>
            <img src={logo} style={{ height: "80px" }} />
            <p
              style={{
                color: "#fff",
                padding: "20px",
                width: "370px",
                textAlign: "justify",
                fontSize: "20px",
              }}
            >
              ما مفتخریم در طول سال‌های گذشته رضایت اغلب مشتریان و تولید کنندگان
              را جلب کرده‌ایم . با توجه به افزایش روز افزون تقاضا، در صدد آنیم
              با توسعه شرکت کیمیا گستر و در عین حال حفظ کیفیت محصولات ارائه شده،
              شرایط بهتری را برای مشتریانمان فراهم کنیم. چرا که هدف ما، رضایت
              مشتریان و برداشتن گامی موثر در پیشرفت کسب و کار آن‌ها است.
            </p>
          </div>
          <div className={styles.ft}>
            <div className={styles.container1}>
              {/* <>-------------------1---------------------</> */}

              <div className={styles.footerItem}>
                <div
                  style={{
                    color: "#fff",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                  className={styles.f1}
                >
                  برخی محصولات کیمیا گستر
                </div>
                <div>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="/soil/peat-soil"
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                        paddingBlock: "5px",
                      }}
                    >
                      خاک پیت شمال کشور{" "}
                    </div>
                  </a>
                </div>

                <div>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="/soil/sphagnum-soil"
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                        paddingBlock: "5px",
                      }}
                    >
                      اسفگنوم{" "}
                    </div>
                  </a>
                </div>

                <div>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                      paddingBlock: "5px",
                    }}
                  >
                    کمپوست{" "}
                  </div>
                </div>
              </div>

              {/* <>-------------------2---------------------</> */}

              <div className={styles.footerItem}>
                <div
                  style={{
                    color: "#fff",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                  className={styles.f1}
                >
                  دسترسی سریع
                </div>
                <div>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="/about-us"
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                        paddingBlock: "5px",
                      }}
                    >
                      درباره ما{" "}
                    </div>
                  </a>
                </div>

                <div>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="/contact-us"
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                        paddingBlock: "5px",
                      }}
                    >
                      تماس با ما{" "}
                    </div>
                  </a>
                </div>

                <div>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href={`https://kimiagostarsoil.com/blog`}
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        paddingBlock: "5px",
                      }}
                    >
                      وبلاگ{" "}
                    </div>
                  </a>
                </div>
              </div>
              {/* <>-------------------3---------------------</> */}
              <div className={styles.footerItem}>
                <div
                  style={{
                    color: "#fff",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                  className={styles.f1}
                >
                  راه‌های ارتباطی
                </div>
                <div style={{ paddingBlock: "5px" }}>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="tel:09183141367"
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      شماره تماس{" "}
                    </div>
                    <div style={{ color: "#fff", fontSize: "14px" }}>
                      09183141367{" "}
                    </div>
                  </a>
                </div>

                <div style={{ paddingBlock: "5px" }}>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="https://www.instagram.com/kimiagostarsoil?igsh=MTY4YTZzNWM5bjBhMw=="
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      اینستاگرام{" "}
                    </div>
                    <div style={{ color: "#fff", fontSize: "14px" }}>
                      kimiagostarsoil{" "}
                    </div>
                  </a>
                </div>

                <div style={{ paddingBlock: "5px" }}>
                  <a
                    style={{ all: "unset", cursor: "pointer" }}
                    href="https://t.me/M_R_amyri"
                  >
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      تلگرام{" "}
                    </div>
                    <div
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        direction: "rtl",
                      }}
                    >
                      @M_R_amyri{" "}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",

            marginTop: "20px",
            borderTop: "3px solid #4F9468",
            direction: "ltr",
          }}
        >
          <div className={styles.social_medias_links}>
            <a href="https://t.me/M_R_amyri" style={{ all: "unset" }}>
              <img
                src={telicon}
                style={{ width: "25px", height: "50px", cursor: "pointer" }}
              />
            </a>
            <a
              href="https://www.instagram.com/kimiagostarsoil"
              style={{ all: "unset" }}
            >
              <img
                src={insticon}
                style={{
                  width: "25px",
                  height: "50px",
                  cursor: "pointer",
                  color: "#fff",
                  marginInline: "30px",
                }}
              />
            </a>

            <a href="#" style={{ all: "unset" }}>
              <img
                src={linkicon}
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "7px",
                  paddingTop: "5px",
                  cursor: "pointer",
                  color: "#fff",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
