import styles from "./products.module.css";
import pimg from "../../assets/images/product-image.svg";
import p1img from "../../assets/images/landing/Group 10.svg";
import p2img from "../../assets/images/landing/Group 12.svg";
import p3img from "../../assets/images/landing/Group 12.svg";
import st from "../../assets/images/Screenshot (36) 2.png";
import timg from "../../assets/icons/icons8-correct.svg";
import image from "../../assets/images/header.svg";
import imagebig from "../../assets/images/kkkkkkkk.svg";

import { useState } from "react";

import { Helmet } from "react-helmet";
function Products() {
  const [product1, setproduct1] = useState("soil-peat");
  const [product2, setproduct2] = useState("soil-sphagnum");
  const [product3, setproduct3] = useState("pr3");
  const screenwidth = window.innerWidth;
  return (
    <>
      {/* <div className={styles.header}>
        <h1 className={styles.headertext}>لورم ایپسوم</h1>
      </div> */}

      <Helmet>
        <title>
          خاک پوششی، خرید بستر رویش قارچ، خرید خاک قارچ | کیمیا گستر
        </title>
        <meta
          name="description"
          content="خاک پوششی تولید شده در کیمیا گستر بستری مناسب برای رشد قارچ فراهم می‌کند. از این رو خاک پوششی یکی از اجزای اصلی و مهم در فرایند پرورش قارچ به شمار می‌رود. "
        />
      </Helmet>

      <div className={styles.headerPhoto}>
        {screenwidth < 1800 ? (
          <img src={image} alt="poster" />
        ) : (
          <img src={imagebig} alt="poster" />
        )}
        <span>انواع خاک تولید شده در کیمیا گستر</span>
      </div>

      <p
        className={styles.pp}
        style={{ direction: "rtl", textAlign: "justify" }}
      >
        کیمیا گستر ارائه کننده انواع خاک‌های پوششی بستر رویش قارچ. خاک پوششی
        بستری مناسب برای رشد قارچ فراهم می‌کند و به همین دلیل از اجزای اصلی و
        مهم در فرایند پرورش قارچ به شمار می‌رود. خاک بهترین تکیه گاه در رشد قارچ
        است و می‌تواند بر تقویت و افزایش محصول اثر قابل توجهی بگذارد. هدف مجموعه
        کیمیا گستر ارائه خاک پوششی مرغوب به منظور افزایش کیفیت و سطح تولید محصول
        شما است.
      </p>

      {/* <>---------------1-----------------</> */}

      <div className={styles.productSecrion}>
        <a href={`/soil/${product1}`} style={{ all: "unset", width: "40%" }}>
          <img
            src={p1img}
            className={styles.pimg}
            style={{ marginRight: "10%" }}
          />
        </a>
        <div className={styles.producttext}>
          <p className={styles.ptext1}>خاک پیت (peat) شمال کشور </p>

          <p className={styles.ptext2}>
            پیت (peat) شمال کشور از مرداب‌ها، تالاب‌ها و آب بندان‌ها برداشت
            می‌شود و به عنوان محرک تغییر دهنده فاز رویشی زایشی به رویشی عمل
            می‌نماید.
          </p>

          <div style={{ display: "flex", width: "500px", marginTop: "50px" }}>
            <div className={styles.timgs}>
              <img src={timg} style={{ width: "30px", paddingTop: "7px" }} />
              <img
                src={timg}
                className={styles.timg2}
                style={{ width: "30px", paddingTop: "23px" }}
              />
            </div>

            <div
              style={{
                color: "#3F3F3F",
              }}
            >
              <div className={styles.endt}>
                حاوی مقادیر مناسبی از عناصر مورد نیاز برای رشد گیاهان.{" "}
              </div>
              <div className={styles.endt2}>
                دارای ساختار مسامی و توپر با تهویه مناسب و نگهداری رطوبت لازم.
              </div>
            </div>
          </div>

          <div className="btn">
            <a href="tel:09183141367" style={{ all: "unset" }}>
              <button className={styles.cbutton}>با ما تماس بگیرید</button>
            </a>
          </div>
        </div>
      </div>

      {/* <>---------------2-----------------</> */}

      <div className={styles.productSecrion}>
        <a href={`/soil/${product2}`} style={{ all: "unset", width: "40%" }}>
          <img
            src={p2img}
            className={styles.pimg}
            style={{ marginRight: "10%" }}
          />
        </a>
        <div className={styles.producttext}>
          <p className={styles.ptext1}>خاک اسفاگنوم </p>

          <p className={styles.ptext2}>
            اسفاگنوم (Sphagnum) یکی از بهترین خاک‌های مورد استفاده در ترکیب
            فرآوری خاک می‌باشد. خاک اسفاگنوم از دو نوع خاک به نام‌ پیت قهوه‌ای
            (brown peat) و پیت سیاه (black peat) تشکیل شده است. هر یک از این
            خاک‌ها بر باروری، حفظ کیفیت و تامین رطوبت مورد نیاز قارچ اثر گذارند.
          </p>

          <div style={{ display: "flex", width: "500px", marginTop: "50px" }}>
            <div className={styles.timgs}>
              <img src={timg} style={{ width: "30px", paddingTop: "7px" }} />
              <img
                src={timg}
                className={styles.timg2}
                style={{ width: "30px", paddingTop: "23px" }}
              />
            </div>
            <div
              style={{
                color: "#3F3F3F",
              }}
            >
              <div className={styles.endt}>
                غنی از مواد آلی است و بستری حاصلخیز برای رشد قارچ‌ها.{" "}
              </div>
              <div className={styles.endt2}>
                با خاصیت نگهداری رطوبت و هوا گردانی مناسب.
              </div>
            </div>
          </div>

          <div className="btn">
            <a href="tel:09183141367" style={{ all: "unset" }}>
              <button className={styles.cbutton}>با ما تماس بگیرید</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
