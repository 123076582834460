import styles from "./errorpage.module.css";
import { useNavigate } from "react-router-dom";
import errorimg from "../../assets/images/404.svg";
import tikimg from "../../assets/icons/icons8-correct.svg";
import image from "../../assets/images/header.svg";
import imagebig from "../../assets/images/kkkkkkkk.svg";

const ErrorPage = () => {
  const navigate = useNavigate();
  const redirectHandler = () => {
    navigate("/");
  };

  window.history.pushState(null, null, document.URL);
  window.addEventListener("popstate", function () {
    window.location.replace(`/`);
  });
  const screenwidth = window.innerWidth;
  return (
    <>
      <div className={styles.headerPhoto}>
        {screenwidth < 1800 ? (
          <img src={image} alt="poster" />
        ) : (
          <img src={imagebig} alt="poster" />
        )}
        <span> 404 - صفحه مورد نظر یافت نشد</span>
      </div>

      <div className={styles.container1}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "100px",
          }}
          className={styles.errorimgcontainer}
        >
          <img src={errorimg} className={styles.errorImage} />
          <div
            style={{
              display: "flex",
              marginTop: "50px",
            }}
          >
            <button className={styles.button}>kimiagostarsoil</button>
            <button className={styles.button}>0918 314 1367</button>
          </div>
        </div>

        <div style={{ direction: "rtl", paddingTop: "120px" }}>
          <h1 style={{ color: "#4F9468", fontSize: "32px" }}>
            برخی لینک‌های مفید
          </h1>
          <div
            style={{
              display: "flex",
              direction: "rtl",
              paddingTop: "20px",
            }}
          >
            <div className={styles.images}>
              <div>
                <img
                  src={tikimg}
                  style={{ width: "20px", paddingTop: "7px" }}
                />
              </div>
              <div>
                <img
                  src={tikimg}
                  style={{ width: "20px", paddingTop: "15px" }}
                />
              </div>
              <div>
                <img
                  src={tikimg}
                  style={{ width: "20px", paddingTop: "20px" }}
                />
              </div>
              <div>
                <img
                  src={tikimg}
                  style={{ width: "20px", paddingTop: "21px" }}
                />
              </div>
              <div>
                <img
                  src={tikimg}
                  style={{ width: "20px", paddingTop: "24px" }}
                />
              </div>
            </div>
            <div style={{ direction: "rtl", paddingTop: "10px" }}>
              <div
                style={{
                  display: "flex",

                  color: "#676565",
                  fontSize: "18px",
                }}
              >
                <a style={{ all: "unset", cursor: "pointer" }} href="/">
                  صفحه اصلی
                </a>
              </div>
              <div
                style={{
                  display: "flex",

                  color: "#676565",
                  fontSize: "18px",
                  marginTop: "22px",
                }}
              >
                <a
                  style={{ all: "unset", cursor: "pointer" }}
                  href="/contact-us"
                >
                  {" "}
                  تماس با ما
                </a>
              </div>
              <div
                style={{
                  display: "flex",

                  color: "#676565",
                  fontSize: "18px",
                  marginTop: "26px",
                }}
              >
                <a style={{ all: "unset", cursor: "pointer" }} href="/about-us">
                  {" "}
                  درباره ما
                </a>
              </div>
              <div
                style={{
                  display: "flex",

                  color: "#676565",
                  fontSize: "18px",
                  marginTop: "30px",
                }}
              >
                کمپوست
              </div>

              <div
                style={{
                  display: "flex",

                  color: "#676565",
                  fontSize: "18px",
                  marginTop: "32px",
                }}
              >
                <a style={{ all: "unset", cursor: "pointer" }} href="/soil">
                  خاک
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
