import "./midComponent.css";
function MidComponent({ midValue }) {
  return (
    <div
      className="mid-component"
      style={{ direction: "rtl", textAlign: "justify" }}
    >
      <div>
        <img src={midValue.src} alt="image" />
      </div>
      <h3>{midValue.title}</h3>
      <p style={{ direction: "rtl" }}>{midValue.text}</p>
    </div>
  );
}

export default MidComponent;
