import { useParams } from "react-router-dom";
import styles from "./product.module.css";

import timg from "../../../assets/icons/icons8-correct.svg";
import pimg from "../../../assets/images/photo_2023-09-08_21-49-22 2.svg";
// import image from "../../../assets/images/photo_2023-09-07_20-01-07.png";

import image from "../../../assets/images/header.svg";
import imagebig from "../../../assets/images/kkkkkkkk.svg";

import image1 from "../../../assets/images/product/1/s-2-1.svg";
import image2 from "../../../assets/images/product/1/s-2-2.svg";

import image3 from "../../../assets/images/product/2/s-1-1.svg";
import image4 from "../../../assets/images/product/2/s-1-2.svg";

import image5 from "../../../assets/images/product/3/s-3-1.svg";
import image6 from "../../../assets/images/product/3/s-3-2.svg";

import image7 from "../../../assets/images/product/3/s-3-1.svg";
import image8 from "../../../assets/images/product/3/s-3-2.svg";
import { useState } from "react";

import { Helmet } from "react-helmet";

function Product() {
  const param = useParams();
  console.log(param.slug);

  // const midVar = [
  //   {
  //     index: 1,
  //     src1: image1,
  //     src2: image2,
  //   },
  //   {
  //     index: 2,
  //     src1: image3,
  //     src2: image4,
  //   },
  //   {
  //     index: 3,
  //     src1: image5,
  //     src2: image6,
  //   },
  //   {
  //     index: 4,
  //     src1: image7,
  //     src2: image8,
  //   },
  // ];
  const screenwidth = window.innerWidth;
  return (
    <>
      {param.slug === "peat-soil" ? (
        <>
          {/* <div className={styles.header}>
      <h1 className={styles.headertext}>لورم ایپسوم   </h1>
    </div> */}

          <Helmet>
            <title>
              خاک پیت، خرید خاک پیت، خاک پیت (peat) شمال کشور | کیمیا گستر{" "}
            </title>
            <meta
              name="description"
              content="خاک پیت (peat) شمال کشور کیمیا گستر از مرداب‌ها، تالاب‌ها و آب بندان‌ها برداشت می‌شود و به عنوان محرک تغییر دهنده فاز رویشی زایشی به رویشی عمل می‌نماید.
              "
            />
          </Helmet>

          <div className={styles.headerPhoto}>
            {screenwidth < 1800 ? (
              <img src={image} alt="poster" />
            ) : (
              <img src={imagebig} alt="poster" />
            )}
            <span>خاک پیت شمال کشور</span>
          </div>

          <p
            className={styles.p}
            style={{ direction: "rtl", textAlign: "justify" }}
          >
            بلک پیت یا خاک پیت سیاه به عنوان یکی از مؤلفه‌های اصلی در خاک پوششی
            بستر قارچ، نقش مهمی دارد. این نوع خاک به دلیل غنی بودن از مواد آلی
            و، به عنوان یک بستر حاصلخیز برای رشد و توسعه قارچ‌ها عمل می‌کند.
            مواد آلی موجود در بلک پیت به عنوان مخزن ذخیره آب برای قارچ‌ عمل
            می‌کنند و همچنین، خاصیت خاک پیت سیاه در نگهداری رطوبت و هوا گردانی
            مناسب، باعث ایجاد شرایط محیطی مساعد برای رشد و توسعه قارچ‌ها می‌شود.
          </p>

          <div className={styles.imgSection}>
            <div className={styles.jh}>
              <img
                src={
                  param.slug == "peat-soil"
                    ? image1
                    : param.slug == "sphagnum-soil"
                    ? image3
                    : param.slug == "pr3"
                    ? image5
                    : param.slug == "pr4" && image7
                }
                className={styles.pimg}
              />
            </div>
            <div>
              <img
                src={
                  param.slug == "peat-soil"
                    ? image2
                    : param.slug == "sphagnum-soil"
                    ? image4
                    : param.slug == "pr3"
                    ? image6
                    : param.slug == "pr4" && image8
                }
                className={styles.pimg}
              />
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.container2}>
              <div className={styles.cccc}>
                <h2 style={{ color: "#DBDBDB" }}>خرید خاک پیت</h2>
                <h4 style={{ color: "#DBDBDB" }}>
                  برای خرید خاک پیت کیمیا گستر با ما تماس بگیرید.
                </h4>
              </div>
              <div className="btn2">
                <button className={styles.cbutton}>
                  <a href="tel:09183141367" style={{ border: "none" }}>
                    تماس بگیرید
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className={styles.container3}>
            <div className={styles.title}>
              <h1>ویژگی‌های خاک پیت شمال کشور</h1>
            </div>
            <div className={styles.hhh}>
              <div className={styles.container4}>
                <img src={timg} style={{ paddingTop: "15px" }} />
                <div className={styles.eText}>
                  خاک پیت به دلیل از دست رفتن نور توسط مواد آلی و معدنی در بستر
                  خاک، معمولاً رنگ تیره‌ای دارد.
                </div>
              </div>
              <div className={styles.container4}>
                <img src={timg} style={{ paddingTop: "15px" }} />
                <div className={styles.eText}>
                  دارای خاصیت جاذب آب است و به عنوان بستر مناسب برای تأمین بافت
                  خاک، مورد استفاده قرار می‌گیرد.
                </div>
              </div>
              <div className={styles.container4}>
                <img src={timg} style={{ paddingTop: "15px" }} />
                <div className={styles.eText}>
                  خاک پیت به عنوان یک منبع مواد آلی برای تغذیه میکروب‌های مفید
                  خاک اهمیت زیادی دارد.
                </div>
              </div>
              <div className={styles.container4}>
                <img src={timg} style={{ paddingTop: "15px" }} />
                <div className={styles.eText}>
                  خاک پیت از طریق باقیمانده گیاهان مختلف یا تجمع زیستی مواد آلی
                  در بخش‌های زیرین خاک تشکیل می‌شود.
                </div>
              </div>
              <div className={styles.container4}>
                <img src={timg} style={{ paddingTop: "15px" }} />
                <div className={styles.eText}>
                  این خاک غنی از مواد آلی است که از بازدهی گیاهی و
                  باقی‌مانده‌های آلی مختلف مشتق می‌شود.
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        param.slug === "sphagnum-soil" && (
          <>
            {/* <div className={styles.header}>
        <h1 className={styles.headertext}>لورم ایپسوم   </h1>
      </div> */}

            <Helmet>
              <title style={{ direction: "ltr" }}>
                خاک اسفاگنوم، خرید خاک اسفاگنوم (sphagnum) | کیمیا گستر
              </title>
              <meta
                name="description"
                content="خاک اسفاگنوم کیمیا گستر از دو نوع خاک پیت قهوه‌ای و پیت سیاه تشکیل که هر یک از این خاک‌ها بر باروری، حفظ کیفیت و تامین رطوبت مورد نیاز قارچ اثر گذارند.
              "
              />
            </Helmet>

            <div className={styles.headerPhoto}>
              {screenwidth < 1800 ? (
                <img src={image} alt="poster" />
              ) : (
                <img src={imagebig} alt="poster" />
              )}
              <span>خاک اسفاگنوم</span>
            </div>

            <p
              className={styles.p}
              style={{ direction: "rtl", textAlign: "justify" }}
            >
 خاک اسفاگنوم قهوه‌ای، به عنوان یک جزء از بافت خاک پیت، می‌تواند تاثیرات متنوعی داشته باشد. این نوع خاک باعث افزایش حاصلخیزی و مقاومت خاک می‌شود. خاک اسفاگنوم، از نظر تهویه هوا، رطوبت و تأمین مواد لازم جهت میکروب‌ها و باکتریهای مفید خاک که در فرآیند تجزیه و تحلیل مواد آلی، دخالت دارند، موثر است. این بافت خاک باعث افزایش قابلیت ذخیره‌سازی رطوبت و نگهداری مواد آلی و عناصر مفید در خاک پیت می‌شود، که این امر می‌تواند برای رشد و میکروب‌های خاکی مفید با اهمیت باشد.
            </p>

            <div className={styles.imgSection}>
              <div className={styles.jh}>
                <img
                  src={
                    param.slug == "peat-soil"
                      ? image1
                      : param.slug == "sphagnum-soil"
                      ? image3
                      : param.slug == "pr3"
                      ? image5
                      : param.slug == "pr4" && image7
                  }
                  className={styles.pimg}
                />
              </div>
              <div>
                <img
                  src={
                    param.slug == "peat-soil"
                      ? image2
                      : param.slug == "sphagnum-soil"
                      ? image4
                      : param.slug == "pr3"
                      ? image6
                      : param.slug == "pr4" && image8
                  }
                  className={styles.pimg}
                />
              </div>
            </div>

            <div className={styles.container}>
              <div className={styles.container2}>
                <div className={styles.cccc}>
                  <h2 style={{ color: "#DBDBDB" }}>خرید خاک اسفاگنوم</h2>
                  <h4 style={{ color: "#DBDBDB" }}>
                    برای خرید خاک اسفاگنوم کیمیا گستر با ما تماس بگیرید.
                  </h4>
                </div>
                <div className="btn2">
                  <button className={styles.cbutton}>
                    <a href="tel:09183141367" style={{ border: "none" }}>
                      تماس بگیرید
                    </a>
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.container3}>
              <div className={styles.title}>
                <h1>ویژگی‌های خاک اسفاگنوم</h1>
              </div>
              <div className={styles.hhh}>
                <div className={styles.container4}>
                  <img src={timg} style={{ paddingTop: "15px" }} />
                  <div className={styles.eText}>
                  خاک اسفاگنوم به عنوان یکی از اجزاء اصلی در خاک پوششی بستر قارچ نقش مهمی دارد.
                  </div>
                </div>
                <div className={styles.container4}>
                  <img src={timg} style={{ paddingTop: "15px" }} />
                  <div className={styles.eText}>
                  این خاک یک بستر مناسب برای رشد قارچ‌ها بوده و از نظر تهویه و رطوبت راندمان تولید را افزایش می‌دهد.
                  </div>
                </div>
                <div className={styles.container4}>
                  <img src={timg} style={{ paddingTop: "15px" }} />
                  <div className={styles.eText}>
                  دارای ساختار مسامی و توپر است که اجازه تهویه مناسب و نگهداری رطوبت لازم برای رشد قارچ‌ را می‌دهد.
                  </div>
                </div>
                <div className={styles.container4}>
                  <img src={timg} style={{ paddingTop: "15px" }} />
                  <div className={styles.eText}>
                  خاک اسفاگنوم حاوی مواد آلی زیادی هستند که منبع مخزن آب برای قارچ‌ می‌شوند
                  </div>
                </div>
                <div className={styles.container4}>
                  <img src={timg} style={{ paddingTop: "15px" }} />
                  <div className={styles.eText}>
                  این خاک دارای ساختار هوموژن و مناسبی برای رشد و تکثیر قارچ‌ها هستند.
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
}

export default Product;
